import * as React from 'react';
import { SafeAreaView, StyleSheet, View } from 'react-native';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import { TextInput, Text, HelperText } from 'react-native-paper';

import round from '../components/round';
import durationInDays from '../components/durationInDays';
import intersectInDays from '../components/intersectInDays';
import Information from '../components/Information';
import DebitsDateRangePickerButtonAndModal from '../components/DebitsDateRangePickerButtonAndModal';

let financeInit = {
  lease: {
    startDate: Date(),
    endDate: Date(),
  },
  chargesLocativesHorsTeom: {
    input: 0,
    startDate: Date(),
    endDate: Date(),
    appliedValue: 0,
  },
  teom: {
    input: 0,
    startDate: Date(),
    endDate: Date(),
    appliedValue: 0,
  },
  retenues: 0,
  arrieresDeLoyer: 0,
  totalDesDebits: 0,
};

function DateRangePickerButtonChargesLocativesHorsTeom(
  finance = financeInit,
  setChargesLocativesHorsTeomAppliedValue = (value: number) => {}
) {
  const [range, setRange] = React.useState({
    startDate: finance.chargesLocativesHorsTeom.startDate,
    endDate: finance.chargesLocativesHorsTeom.endDate,
  });
  const [open, setOpen] = React.useState(false);
  const [intersect, setIntersect] = React.useState(1);
  const [proportion, setProportion] = React.useState(1);

  const onConfirm = React.useCallback(
    ({ startDate, endDate }) => {
      setOpen(false);
      setRange({ startDate, endDate });
      const intersectLocal = intersectInDays(finance.lease, {
        startDate,
        endDate,
      });
      setIntersect(intersectLocal);
      const proportionLocal =
        intersectLocal / durationInDays({ startDate, endDate });
      setProportion(proportionLocal);
      const chargesLocativesHorsTeomAppliedValueLocal = round(
        finance.chargesLocativesHorsTeom.input * proportionLocal,
        2
      );
      setChargesLocativesHorsTeomAppliedValue(
        chargesLocativesHorsTeomAppliedValueLocal
      );
    },
    [
      finance,
      setOpen,
      setRange,
      setIntersect,
      setProportion,
      setChargesLocativesHorsTeomAppliedValue,
    ]
  );

  finance.chargesLocativesHorsTeom.startDate = range.startDate;
  finance.chargesLocativesHorsTeom.endDate = range.endDate;

  return DebitsDateRangePickerButtonAndModal(
    open,
    setOpen,
    range,
    onConfirm,
    intersect,
    proportion
  );
}

function DateRangePickerButtonTeom(
  finance = financeInit,
  setTeomAppliedValue = (value: number) => {}
) {
  const [range, setRange] = React.useState({
    startDate: finance.teom.startDate,
    endDate: finance.teom.endDate,
  });
  const [open, setOpen] = React.useState(false);
  const [intersect, setIntersect] = React.useState(1);
  const [proportion, setProportion] = React.useState(1);

  const onConfirm = React.useCallback(
    ({ startDate, endDate }) => {
      setOpen(false);
      setRange({ startDate, endDate });
      const intersectLocal = intersectInDays(finance.lease, {
        startDate,
        endDate,
      });
      setIntersect(intersectLocal);
      const proportionLocal =
        intersectLocal / durationInDays({ startDate, endDate });
      setProportion(proportionLocal);
      const teomAppliedValueLocal = round(
        finance.teom.input * proportionLocal,
        2
      );
      setTeomAppliedValue(teomAppliedValueLocal);
    },
    [
      finance,
      setOpen,
      setRange,
      setIntersect,
      setProportion,
      setTeomAppliedValue,
    ]
  );

  finance.teom.startDate = range.startDate;
  finance.teom.endDate = range.endDate;

  return DebitsDateRangePickerButtonAndModal(
    open,
    setOpen,
    range,
    onConfirm,
    intersect,
    proportion
  );
}

function InformationChargesLocativesHorsTeom() {
  const title = 'Charges locatives hors TEOM';
  const body =
    "Les charges locatives (ou charges récupérables) sont des dépenses dues par le locataire, mais initialement payées par le propriétaire. Le propriétaire se fait rembourser ces dépenses par le locataire. Selon qu'il s'agit d'un bail d'habitation (vide ou meublé) ou d'un bail mobilité, le paiement de ces charges par le locataire se fait soit par le versement de provisions pour charges avec régularisation annuelle, soit par forfait.";
  const sourceLink =
    'https://www.service-public.fr/particuliers/vosdroits/F947';
  return Information(title, body, sourceLink);
}

function InformationTeom() {
  const title = 'TEOM';
  const body =
    "Taxe d'enlèvement des ordures ménagères. La TEOM est calculée sur la même base que la taxe foncière, c'est-à-dire la moitié de la valeur locative cadastrale de la propriété. Le montant de la taxe est égal à la base retenue multipliée par le taux fixé par la collectivité. Des frais de gestion de la fiscalité locale s'ajoutent au montant de la taxe.";
  const sourceLink =
    'https://www.demarches.interieur.gouv.fr/particuliers/taxe-redevance-enlevement-ordures-menageres-teom-reom';
  return Information(title, body, sourceLink);
}

function InformationRetenues() {
  const title = 'Retenues';
  const body =
    "Une retenue est une somme que le propriétaire peut de garder. Cette somme peut correspondre à un montant restant dû par le locataire au propriétaire. Par exemple, il peut s'agir d'un impayé de loyers ou de charges, de frais liés à des dégradations ou à des travaux locatifs non faits. En cas de retenue, le propriétaire doit remettre au locataire des documents justificatifs. Par exemple, il peut s'agir des documents suivants : états des lieux d'entrée et de sortie, photos, constat fait par un commissaire de justice (anciennement constat d'huissier de justice), factures ou devis de travaux, lettre de réclamation des loyers impayés restée sans réponse...";
  const sourceLink =
    'https://www.service-public.fr/particuliers/vosdroits/F31269';
  return Information(title, body, sourceLink);
}

function InformationArrieresDeLoyer() {
  const title = 'Arriérés de loyer';
  const body =
    "Les arriérés de loyer sont les loyers impayés, c'est-à-dire qui ont un retard de paiement toujours en cours.";
  return Information(title, body);
}

export default function DebitsRoute(finance = financeInit) {
  const [chargesLocativesHorsTeomString, setChargesLocativesHorsTeomString] =
    React.useState(String(finance.chargesLocativesHorsTeom.input));
  const [
    chargesLocativesHorsTeomAppliedValue,
    setChargesLocativesHorsTeomAppliedValue,
  ] = React.useState(finance.chargesLocativesHorsTeom.appliedValue);
  const [teomString, setTeomString] = React.useState(
    String(finance.teom.input)
  );
  const [teomAppliedValue, setTeomAppliedValue] = React.useState(
    finance.teom.appliedValue
  );
  const [retenuesString, setRetenuesString] = React.useState(
    String(finance.retenues)
  );
  const [arrieresDeLoyerString, setArrieresDeLoyerString] = React.useState(
    String(finance.arrieresDeLoyer)
  );

  function setChargesLocativesHorsTeomStringAndAppliedValue(
    chargesLocativesHorsTeomStringLocal = '0',
    finance = financeInit
  ) {
    setChargesLocativesHorsTeomString(chargesLocativesHorsTeomStringLocal);
    let proportionLocal = 1;
    const startDateLocal = finance.chargesLocativesHorsTeom.startDate;
    const endDateLocal = finance.chargesLocativesHorsTeom.endDate;
    if (startDateLocal !== undefined && endDateLocal !== undefined) {
      const intersectLocal = intersectInDays(finance.lease, {
        startDate: startDateLocal,
        endDate: endDateLocal,
      });
      proportionLocal =
        intersectLocal /
        durationInDays({ startDate: startDateLocal, endDate: endDateLocal });
    }
    const chargesLocativesHorsTeomAppliedValueLocal: number = round(
      +chargesLocativesHorsTeomStringLocal * proportionLocal,
      2
    );
    setChargesLocativesHorsTeomAppliedValue(
      chargesLocativesHorsTeomAppliedValueLocal
    );
  }

  function setTeomStringAndAppliedValue(
    teomStringLocal = '0',
    finance = financeInit
  ) {
    setTeomString(teomStringLocal);
    let proportionLocal = 1;
    const startDateLocal = finance.teom.startDate;
    const endDateLocal = finance.teom.endDate;
    if (startDateLocal !== undefined && endDateLocal !== undefined) {
      const intersectLocal = intersectInDays(finance.lease, {
        startDate: startDateLocal,
        endDate: endDateLocal,
      });
      proportionLocal =
        intersectLocal /
        durationInDays({ startDate: startDateLocal, endDate: endDateLocal });
    }
    const teomAppliedValueLocal: number = round(
      +teomStringLocal * proportionLocal,
      2
    );
    setTeomAppliedValue(teomAppliedValueLocal);
  }

  let chargesLocativesHorsTeomInput: number = +chargesLocativesHorsTeomString;
  let teomInput: number = +teomString;
  let retenues: number = +retenuesString;
  let arrieresDeLoyer: number = +arrieresDeLoyerString;
  let totalDesDebits =
    chargesLocativesHorsTeomAppliedValue +
    teomAppliedValue +
    retenues +
    arrieresDeLoyer;
  totalDesDebits = round(totalDesDebits, 2);

  finance.chargesLocativesHorsTeom.input = chargesLocativesHorsTeomInput;
  finance.chargesLocativesHorsTeom.appliedValue =
    chargesLocativesHorsTeomAppliedValue;
  finance.teom.input = teomInput;
  finance.teom.appliedValue = teomAppliedValue;
  finance.retenues = retenues;
  finance.arrieresDeLoyer = arrieresDeLoyer;
  finance.totalDesDebits = totalDesDebits;
  return (
    <SafeAreaView style={styles.content}>
      <KeyboardAwareScrollView>
        <Text variant="titleLarge" accessibilityRole="heading">
          Débits
        </Text>
        <View style={styles.row}>
          <TextInput
            label="Charges locatives hors TEOM"
            style={styles.inputContainerStyle}
            onChangeText={(chargesLocativesHorsTeomStringLocal) =>
              setChargesLocativesHorsTeomStringAndAppliedValue(
                chargesLocativesHorsTeomStringLocal,
                finance
              )
            }
            value={chargesLocativesHorsTeomString}
            error={isNaN(+chargesLocativesHorsTeomString)}
            placeholder={String(finance.chargesLocativesHorsTeom.input)}
            keyboardType="numeric"
            right={<TextInput.Affix text="€" />}
          />
          <InformationChargesLocativesHorsTeom />
        </View>
        {DateRangePickerButtonChargesLocativesHorsTeom(
          finance,
          setChargesLocativesHorsTeomAppliedValue
        )}
        <HelperText
          type="error"
          visible={isNaN(+chargesLocativesHorsTeomString)}>
          Erreur: Veuillez entrer un montant numérique
        </HelperText>
        <View style={styles.row}>
          <TextInput
            label="TEOM"
            style={styles.inputContainerStyle}
            onChangeText={(teomStringLocal) =>
              setTeomStringAndAppliedValue(teomStringLocal, finance)
            }
            value={teomString}
            error={isNaN(+teomString)}
            placeholder={String(finance.teom.input)}
            keyboardType="numeric"
            right={<TextInput.Affix text="€" />}
          />
          <InformationTeom />
        </View>
        {DateRangePickerButtonTeom(finance, setTeomAppliedValue)}
        <HelperText type="error" visible={isNaN(+teomString)}>
          Erreur: Veuillez entrer un montant numérique
        </HelperText>
        <View style={styles.row}>
          <TextInput
            label="Retenues"
            style={styles.inputContainerStyle}
            onChangeText={setRetenuesString}
            value={retenuesString}
            error={isNaN(+retenuesString)}
            placeholder={String(finance.retenues)}
            keyboardType="numeric"
            right={<TextInput.Affix text="€" />}
          />
          <InformationRetenues />
        </View>
        <HelperText type="error" visible={isNaN(+retenuesString)}>
          Erreur: Veuillez entrer un montant numérique
        </HelperText>
        <View style={styles.row}>
          <TextInput
            label="Arriérés de loyer"
            style={styles.inputContainerStyle}
            onChangeText={setArrieresDeLoyerString}
            value={arrieresDeLoyerString}
            error={isNaN(+arrieresDeLoyerString)}
            placeholder={String(finance.arrieresDeLoyer)}
            keyboardType="numeric"
            right={<TextInput.Affix text="€" />}
          />
          <InformationArrieresDeLoyer />
        </View>
        <HelperText type="error" visible={isNaN(+arrieresDeLoyerString)}>
          Erreur: Veuillez entrer un montant numérique
        </HelperText>
        <Text variant="bodyLarge">Total des débits : {totalDesDebits} €</Text>
      </KeyboardAwareScrollView>
    </SafeAreaView>
  );
}

const styles = StyleSheet.create({
  content: {
    margin: 10,
  },
  inputContainerStyle: {
    flex: 1,
    margin: 8,
  },
  row: {
    flexDirection: 'row',
  },
});
