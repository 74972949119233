import * as React from 'react';
import { Helmet } from 'react-helmet';
import { View, StyleSheet } from 'react-native';
import { Provider as PaperProvider, MD3LightTheme } from 'react-native-paper';
import { MaterialCommunityIcons } from '@expo/vector-icons';

import { NavigationContainer } from '@react-navigation/native';
import { createMaterialBottomTabNavigator } from '@react-navigation/material-bottom-tabs';
const Tab = createMaterialBottomTabNavigator();

const linking = {
  prefixes: [
    'https://www.almacrity.com',
    'https://almacrity.com',
    'almacrity://',
  ],
  config: {
    screens: {
      Bail: '',
      Crédits: 'credits',
      Débits: 'debits',
      Solde: 'solde',
    },
  },
};

import BailRoute from './pages/BailRoute';
import CreditsRoute from './pages/CreditsRoute';
import DebitsRoute from './pages/DebitsRoute';
import SoldeRoute from './pages/SoldeRoute';

export class Lease {
  startDate: Date = Date();
  endDate: Date = Date();
}
let leaseInit: Lease = new Lease();
leaseInit = {
  startDate: undefined,
  endDate: undefined,
};
export class Finance {
  lease: Lease = leaseInit;
  provisionsSurChargesHorsTeom: Number = 0;
  provisionsSurTeom: Number = 0;
  depotDeGarantie: Number = 0;
  totalDesCredits: Number = 0;
  chargesLocativesHorsTeom: {
    input: Number;
    startDate: Date;
    endDate: Date;
    appliedValue: Number;
  };
  teom: {
    input: Number;
    startDate: Date;
    endDate: Date;
    appliedValue: Number;
  };
  retenues: Number = 0;
  arrieresDeLoyer: Number = 0;
  totalDesDebits: Number = 0;
  solde: Number = 0;
}

let financeInit: Finance = new Finance();
financeInit = {
  lease: leaseInit,
  provisionsSurChargesHorsTeom: 0,
  provisionsSurTeom: 0,
  depotDeGarantie: 0,
  totalDesCredits: 0,
  chargesLocativesHorsTeom: {
    input: 0,
    startDate: undefined,
    endDate: undefined,
    appliedValue: 0,
  },
  teom: {
    input: 0,
    startDate: undefined,
    endDate: undefined,
    appliedValue: 0,
  },
  retenues: 0,
  arrieresDeLoyer: 0,
  totalDesDebits: 0,
  solde: 0,
};

export default function App() {
  const [finance, setFinance] = React.useState(financeInit);

  return (
    <PaperProvider theme={MD3LightTheme}>
      <Helmet>
        <meta charSet="utf-8" />
        <meta http-equiv="content-language" content="fr-fr" />
        <meta
          name="description"
          content="Almacrity encourage un immobilier durable, pour tous. L'application apporte la transparence sur tous les montants que les propriétaires ont déjà reçus des locataires (crédits), et sur tous les montants que les propriétaires doivent aux locataires (débits). Le solde calculé est la différence entre les crédits et les débits, ce qui permet de savoir qui doit à qui et combien. Nous espérons qu'avec une plus grande transparence, les propriétaires et les locataires pourront mettre fin à leur bail en toute sérénité."
        />
        <meta http-equiv="Content-Security-Policy" content="default-src https:" />
        <title>Almacrity End of lease / Fin de bail</title>
        <link rel="canonical" href="https://www.almacrity.com" />
      </Helmet>
      <NavigationContainer
        linking={linking}
        documentTitle={{
          formatter: (options, route) =>
            `${
              options?.title ?? route?.name
            } - Almacrity End of lease / Fin de bail`,
        }}>
        <View style={styles.container}>
          <Tab.Navigator
            initialRouteName="Bail"
            shifting={true}
            sceneAnimationEnabled={false}>
            <Tab.Screen
              name="Bail"
              component={() => BailRoute(finance)}
              options={{
                tabBarIcon: ({ focused }) =>
                  focused ? (
                    <MaterialCommunityIcons name="home" size={26} />
                  ) : (
                    <MaterialCommunityIcons name="home-outline" size={26} />
                  ),
              }}
            />
            <Tab.Screen
              name="Crédits"
              component={() => CreditsRoute(finance)}
              options={{
                tabBarIcon: ({ focused }) =>
                  focused ? (
                    <MaterialCommunityIcons
                      name="arrow-down-circle"
                      size={26}
                    />
                  ) : (
                    <MaterialCommunityIcons
                      name="arrow-down-circle-outline"
                      size={26}
                    />
                  ),
              }}
            />
            <Tab.Screen
              name="Débits"
              component={() => DebitsRoute(finance)}
              options={{
                tabBarIcon: ({ focused }) =>
                  focused ? (
                    <MaterialCommunityIcons name="arrow-up-circle" size={26} />
                  ) : (
                    <MaterialCommunityIcons
                      name="arrow-up-circle-outline"
                      size={26}
                    />
                  ),
              }}
            />
            <Tab.Screen
              name="Solde"
              component={() => SoldeRoute(finance)}
              options={{
                tabBarIcon: ({ focused }) =>
                  focused ? (
                    <MaterialCommunityIcons name="equal-box" size={26} />
                  ) : (
                    <MaterialCommunityIcons name="equal" size={26} />
                  ),
              }}
            />
          </Tab.Navigator>
        </View>
      </NavigationContainer>
    </PaperProvider>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
});
