import * as React from 'react';
import { Image, View, SafeAreaView, StyleSheet } from 'react-native';
import { Text, Button } from 'react-native-paper';
import {
  DatePickerModal,
  fr,
  registerTranslation,
} from 'react-native-paper-dates';
registerTranslation('fr', fr);

import durationInDays from '../components/durationInDays';

let financeInit = {
  lease: {
    startDate: undefined,
    endDate: undefined,
  },
};

function DateRangePickerButton(finance = financeInit) {
  const buttonText = 'Sélectionner une période';

  const [range, setRange] = React.useState({
    startDate: finance.lease.startDate,
    endDate: finance.lease.endDate,
  });
  const [open, setOpen] = React.useState(false);

  const onDismiss = React.useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  const onConfirm = React.useCallback(
    ({ startDate, endDate }) => {
      setOpen(false);
      setRange({ startDate, endDate });
    },
    [setOpen, setRange]
  );

  finance.lease.startDate = range.startDate;
  finance.lease.endDate = range.endDate;

  return (
    <View>
      <Button onPress={() => setOpen(true)} uppercase={false} mode="outlined">
        {buttonText}
      </Button>
      <DatePickerModal
        mode="range"
        locale="fr"
        visible={open}
        onDismiss={onDismiss}
        startDate={range.startDate}
        endDate={range.endDate}
        onConfirm={onConfirm}
      />
      <Text variant="bodyLarge">
        {range.startDate === undefined
          ? ''
          : `Soit une durée de ${durationInDays(range)} jours.`}
      </Text>
    </View>
  );
}

export default function BailRoute(finance = financeInit) {
  return (
    <SafeAreaView style={styles.content}>
      <Image style={styles.logo} source={require('../assets/icon.png')} />
      <Text variant="headlineLarge" accessibilityRole="heading">
        Solde de fin de bail
      </Text>
      <Text variant="bodyLarge" accessibilityRole="text">
        Calculez le solde de fin de bail pour savoir combien les bailleurs
        doivent restituer aux locataires, ou au contraire combien les locataires
        doivent abonder à destination des bailleurs.{'\n'}
        {'\n'}
      </Text>
      <Text variant="bodyLarge" accessibilityRole="text">
        Période de la location
      </Text>
      {DateRangePickerButton(finance)}
    </SafeAreaView>
  );
}

const styles = StyleSheet.create({
  content: {
    margin: 10,
  },
  logo: {
    width: 100,
    height: 100,
    padding: 50,
  },
});
