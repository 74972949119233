import round from './round';

const rangeInit = {
  startDate: Date(),
  endDate: Date(),
};

function convertToDays(duration = 0) {
  return round(duration / 1000 / 3600 / 24, 0);
}

export default function durationInDays(range = rangeInit) {
  return convertToDays(range.endDate - range.startDate);
}