import * as React from 'react';
import { View } from 'react-native';
import * as Linking from 'expo-linking';
import { Text, Button, IconButton, Portal, Dialog } from 'react-native-paper';

export default function Information(
  title = 'Titre',
  body = 'Définition',
  sourceLink = 'https://www.almacrity.com'
) {
  const [visible, setVisible] = React.useState(false);
  const showDialog = () => setVisible(true);
  const hideDialog = () => setVisible(false);
  return (
    <View>
      <IconButton icon="information-outline" size={24} onPress={showDialog} />
      <Portal>
        <Dialog visible={visible} onDismiss={hideDialog}>
          <Dialog.Title>{title}</Dialog.Title>
          <Dialog.Content>
            <Text variant="bodyMedium">{body}</Text>
          </Dialog.Content>
          <Dialog.Actions>
            <Button onPress={() => Linking.openURL(sourceLink)}>Source</Button>
            <Button onPress={hideDialog}>OK</Button>
          </Dialog.Actions>
        </Dialog>
      </Portal>
    </View>
  );
}
