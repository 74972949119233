import * as React from 'react';
import { StyleSheet, SafeAreaView, View, Platform } from 'react-native';
import { Text, Button, TextInput, Divider } from 'react-native-paper';
import { Formik, Form } from 'formik';

import round from '../components/round';

let financeInit = {
  totalDesCredits: 0,
  totalDesDebits: 0,
  solde: 0,
};

const encode = (data) => {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&');
};

function MyForm() {
  return (
    <View>
      <Divider style={styles.content} />
      <Text variant="bodyLarge">
        Laissez-nous vos coordonnées pour recevoir les nouvelles d'Almacrity :
      </Text>
      <Formik
        initialValues={{ email: '' }}
        onSubmit={(values, actions) => {
          fetch('/', {
            method: 'POST',
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
            body: encode({ 'form-name': 'contact-demo', ...values }),
          })
            .then(() => {
              alert('Success');
              actions.resetForm();
            })
            .catch(() => {
              alert('Error');
            })
            .finally(() => actions.setSubmitting(false));
        }}
        >
        {({ handleChange, handleBlur, handleSubmit, values }) => (
          <Form name="contact-demo" data-netlify={true}>
            <TextInput
              label="E-mail"
              style={styles.inputContainerStyle}
              onChangeText={handleChange('email')}
              onBlur={handleBlur('email')}
              value={values.email}
              keyboardType="email"
            />
            <Button onPress={handleSubmit}>Envoyer</Button>
          </Form>
        )}
      </Formik>
    </View>
  );
}

export default function SoldeRoute(finance = financeInit) {
  let solde = finance.totalDesCredits - finance.totalDesDebits;
  solde = round(solde, 2);
  finance.solde = solde;
  return (
    <SafeAreaView style={styles.content}>
      <Text variant="titleLarge" accessibilityRole="heading">
        Solde
      </Text>
      <Text variant="bodyLarge">
        {finance.totalDesCredits} - {finance.totalDesDebits} = {solde} €{'\n'}
        {solde >= 0
          ? `Les bailleurs doivent ${solde} € aux locataires.`
          : `Les locataires doivent ${-solde} € aux bailleurs.`}
      </Text>
      {Platform.OS === 'web' ? <MyForm /> : ''}
    </SafeAreaView>
  );
}

const styles = StyleSheet.create({
  content: {
    margin: 10,
  },
  inputContainerStyle: {
    flex: 1,
    margin: 8,
  },
});
