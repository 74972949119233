import * as React from 'react';
import { SafeAreaView, StyleSheet, View } from 'react-native';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import { TextInput, Text, HelperText } from 'react-native-paper';

import round from '../components/round';
import Information from '../components/Information';

let financeInit = {
  provisionsSurChargesHorsTeom: 0,
  provisionsSurTeom: 0,
  depotDeGarantie: 0,
  totalDesCredits: 0,
  totalDesDebits: 0,
};

function InformationProvisionsSurChargesLocativesHorsTeom() {
  const title = 'Provisions sur charges locatives hors TEOM';
  const body =
    "La provision sur charge consiste à ce que le locataire règle les frais afférents au logement, frais qui sont en début de bail à la charge du propriétaire. Au lieu de faire régler les charges au locataire en une seule fois, le cadre législatif a instauré le principe de provisions afin que le montant des charges locatives soit lissé sur l'année.";
  const sourceLink = 'https://www.litige.fr/definitions/provision-sur-charge';
  return Information(title, body, sourceLink);
}

function InformationProvisionsSurTeom() {
  const title = 'Provisions sur TEOM';
  const body =
    "La provision sur Taxe d'Enlèvement des Ordures Ménagères (TEOM) consiste à ce que le locataire règle les frais afférents à la TEOM, frais qui sont à la charge du propriétaire chaque année fiscale.";
  const sourceLink = 'https://www.almacrity.com';
  return Information(title, body, sourceLink);
}

function InformationDepotDeGarantie() {
  const title = 'Dépôt de garantie';
  const body =
    "Dans certains cas, le propriétaire peut exiger le versement d'un dépôt de garantie pour couvrir les éventuels futurs manquements du locataire à ses obligations (impayé de loyer ou de charges, absence de réparations locatives...). Ces règles dépendent du bail d'habitation signé : bail d'un logement vide ou bail d'un logement meublé.";
  const sourceLink =
    'https://www.service-public.fr/particuliers/vosdroits/F31269';
  return Information(title, body, sourceLink);
}

export default function CreditsRoute(finance = financeInit) {
  const [
    provisionsSurChargesHorsTeomString,
    setProvisionsSurChargesHorsTeomString,
  ] = React.useState(String(finance.provisionsSurChargesHorsTeom));
  const [provisionsSurTeomString, setProvisionsSurTeomString] = React.useState(
    String(finance.provisionsSurTeom)
  );
  const [depotDeGarantieString, setDepotDeGarantieString] = React.useState(
    String(finance.depotDeGarantie)
  );

  let provisionsSurChargesHorsTeom: number =
    +provisionsSurChargesHorsTeomString;
  let provisionsSurTeom: number = +provisionsSurTeomString;
  let depotDeGarantie: number = +depotDeGarantieString;
  let totalDesCredits =
    provisionsSurChargesHorsTeom + provisionsSurTeom + depotDeGarantie;
  totalDesCredits = round(totalDesCredits, 2);

  finance.provisionsSurChargesHorsTeom = provisionsSurChargesHorsTeom;
  finance.provisionsSurTeom = provisionsSurTeom;
  finance.depotDeGarantie = depotDeGarantie;
  finance.totalDesCredits = totalDesCredits;
  return (
    <SafeAreaView style={styles.content}>
      <KeyboardAwareScrollView>
        <Text variant="titleLarge" accessibilityRole="heading">
          Crédits
        </Text>
        <View style={styles.row}>
          <TextInput
            label="Provisions sur charges hors TEOM"
            style={styles.inputContainerStyle}
            onChangeText={setProvisionsSurChargesHorsTeomString}
            value={provisionsSurChargesHorsTeomString}
            error={isNaN(+provisionsSurChargesHorsTeomString)}
            placeholder={String(finance.provisionsSurChargesHorsTeom)}
            keyboardType="numeric"
            right={<TextInput.Affix text="€" />}
          />
          <InformationProvisionsSurChargesLocativesHorsTeom />
        </View>
        <HelperText
          type="error"
          visible={isNaN(+provisionsSurChargesHorsTeomString)}>
          Erreur: Veuillez entrer un montant numérique
        </HelperText>
        <View style={styles.row}>
          <TextInput
            label="Provisions sur TEOM"
            style={styles.inputContainerStyle}
            onChangeText={setProvisionsSurTeomString}
            value={provisionsSurTeomString}
            error={isNaN(+provisionsSurTeomString)}
            placeholder={String(finance.provisionsSurTeom)}
            keyboardType="numeric"
            right={<TextInput.Affix text="€" />}
          />
          <InformationProvisionsSurTeom />
        </View>
        <HelperText type="error" visible={isNaN(+provisionsSurTeomString)}>
          Erreur: Veuillez entrer un montant numérique
        </HelperText>
        <View style={styles.row}>
          <TextInput
            label="Dépôt de garantie"
            style={styles.inputContainerStyle}
            onChangeText={setDepotDeGarantieString}
            value={depotDeGarantieString}
            error={isNaN(+depotDeGarantieString)}
            placeholder={String(finance.depotDeGarantie)}
            keyboardType="numeric"
            right={<TextInput.Affix text="€" />}
          />
          <InformationDepotDeGarantie />
        </View>
        <HelperText type="error" visible={isNaN(+depotDeGarantieString)}>
          Erreur: Veuillez entrer un montant numérique
        </HelperText>
        <Text variant="bodyLarge">Total des crédits : {totalDesCredits} €</Text>
      </KeyboardAwareScrollView>
    </SafeAreaView>
  );
}

const styles = StyleSheet.create({
  content: {
    margin: 10,
  },
  inputContainerStyle: {
    flex: 1,
    margin: 8,
  },
  row: {
    flexDirection: 'row',
  },
});
