import * as React from 'react';
import { View } from 'react-native';
import { Text, Button } from 'react-native-paper';
import {
  DatePickerModal,
  fr,
  registerTranslation,
} from 'react-native-paper-dates';
registerTranslation('fr', fr);

import round from './round';
import durationInDays from './durationInDays';

const rangeInit = {
  startDate: Date(),
  endDate: Date(),
};

export default function DebitsDateRangePickerButtonAndModal(
  open = false,
  setOpen,
  range = rangeInit,
  onConfirm,
  intersect = 1,
  proportion = 1
) {
  const buttonText = 'Sélectionner une période (optionnel)';

  const onDismiss = React.useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  return (
    <View>
      <Button onPress={() => setOpen(true)} uppercase={false} mode="outlined">
        {buttonText}
      </Button>
      <DatePickerModal
        mode="range"
        locale="fr"
        visible={open}
        onDismiss={onDismiss}
        startDate={range.startDate}
        endDate={range.endDate}
        onConfirm={onConfirm}
      />
      <Text variant="bodyLarge">
        {range.startDate === undefined
          ? ''
          : `Soit une durée de ${durationInDays(
              range
            )} jours, dont ${intersect} recouvrent la période de bail. Par conséquent, ${round(
              proportion * 100,
              2
            )}% du montant est appliqué.`}
      </Text>
    </View>
  );
}
