import round from './round';

const rangeInit = {
  startDate: Date(),
  endDate: Date(),
};

function convertToDays(duration = 0) {
  return round(duration / 1000 / 3600 / 24, 0);
}

export default function intersectInDays(range1 = rangeInit, range2 = rangeInit) {
  // We suppose that range.endDate >= range.startDate
  let intersect = 0;
  if (range2.endDate < range1.startDate || range2.startDate > range1.endDate) {
    intersect = 0;
  } else {
    intersect = Math.min(range1.endDate, range2.endDate) - Math.max(range1.startDate, range2.startDate);
  }
  return convertToDays(intersect);
}